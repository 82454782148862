<template>
  <div>
    <primaryTextfield
        :label="fieldAttributes.name"
        :value="additionalParameters"
        :fieldAttrInput="fieldAttrInput"
        @input="updateField('additionalParameters', ...arguments)"
    ></primaryTextfield>
  </div>
</template>
<script>
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";

export default {
  components: {
    primaryTextfield,
  },
  props: ["result", "fieldAttributes", "field","value","fieldAttrInput"],
  computed: {
    additionalParameters(){
      let ap ="";
      if(typeof this.value!="undefined" &&
          typeof this.value.additionalParameters!="undefined"
      ){
        ap= this.value.additionalParameters;
      }
      return ap;
    } ,
  },
  methods: {
    updateField(index, newVal) {
      let localValue = { ...this.value };
      this.$set(localValue, index, newVal);
      this.$emit("input", localValue);
    },
  },
};
</script>